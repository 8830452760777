import { template as template_00bbe1f1760c486a890827593fa47def } from "@ember/template-compiler";
const SidebarSectionMessage = template_00bbe1f1760c486a890827593fa47def(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
