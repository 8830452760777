import { template as template_a2b2a61354f843f5a3f007491b75c902 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a2b2a61354f843f5a3f007491b75c902(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
